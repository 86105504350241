.app {
  text-align: center;
  background-color: #282c34;
}

.app-logo {
  height: 5vmin;
  min-height: 50px;
  width: auto;
  pointer-events: none;
}

.app-audio {
  height: 100%;
}

.app-header {
  min-height: 98vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 0.5vmin);
  color: white;
}

.app-footer {
  text-align: center;
  font-size: 10px;
  color: white;
  min-height: 2vh;

}

.app-link {
  color: #61dafb;
}